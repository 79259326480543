<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title>Einstellungen</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <!-- Default Segment -->
            <ion-segment @ionChange="segmentChanged($event)">
                <ion-segment-button value="friends">
                    <ion-label>Profil</ion-label>
                </ion-segment-button>
                <ion-segment-button value="enemies">
                    <ion-label>Account</ion-label>
                </ion-segment-button>
            </ion-segment>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { pin, wine, warning, walk, rocket, logoTwitter, logoFacebook, logoInstagram, newspaper, heartOutline, heart, notifications, shareSocial } from "ionicons/icons";

import { defineComponent } from 'vue';
export default defineComponent({
    name: "SettingsPage",
    data: function() {
        return {};
    },
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
    },
    methods: {
        segmentChanged(ev: any) {
            console.log("Segment changed", ev);
        },
    },
    setup() {
        return {
            pin,
            wine,
            warning,
            walk,
            rocket,
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            heartOutline,
            heart,
            notifications,
            shareSocial
        };
    },
});
</script>

<style scoped>
ion-card-title {
    font-family: var(--ion-font-family-light);
    color: var(--ion-color-dark);
}

ion-icon {
    color: var(--ion-color-primary);
}

ion-item.title-gradient > ion-icon {
    color: var(--ion-color-primary-contrast);
}

ion-col > ion-card-subtitle,
ion-item > ion-card-subtitle {
    color: var(--ion-color-primary-contrast);
    font-family: var(--ion-font-family-semi-bold);
    line-height: 100%;
}

ion-col > ion-thumbnail,
ion-item > ion-thumbnail {
    --border-radius: 25%;
    border: 1px var(--ion-color-primary) solid;
    width: 2rem;
    height: 2rem;
}

ion-col > ion-thumbnail > img,
ion-item > ion-thumbnail > img {
    width: calc(2rem - 2px);
    height: calc(2rem - 2px);
}

ion-button {
    color: var(--ion-color-primary);
}

ion-card {
    border-radius: 0.5rem;
}

ion-card-content {
    color: var(--ion-color-dark);
}

.card-text {
    font-family: var(--ion-font-family);
    font-size: 20px;
    color: var(--ion-color-healy-darkblue);
}

.img-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    /*     left: 2rem;
 */
}

.cal-box {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: var(--ion-color-primary-contrast) solid 1px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 0.5rem;
    padding: 0;
    height: 3rem;
    width: 3rem;
    text-align: center;
}

.cal-box > h2 {
    margin-top: 0.125rem;
    margin-bottom: -0.125rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.cal-box > ion-text {
    font-family: var(--ion-font-family-medium);
    font-size: 0.75rem;
    line-height: 100%;
}

.imageholder {
    position: relative;
    width: 100%;
    padding-bottom: 56.75%;
    background-size: cover;
}

.title-gradient {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.4) 60%,
        rgba(0, 0, 0, 0) 100%
    );
}
</style>