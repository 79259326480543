
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { pin, wine, warning, walk, rocket, logoTwitter, logoFacebook, logoInstagram, newspaper, heartOutline, heart, notifications, shareSocial } from "ionicons/icons";

import { defineComponent } from 'vue';
export default defineComponent({
    name: "SettingsPage",
    data: function() {
        return {};
    },
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonTitle,
        IonToolbar,
    },
    methods: {
        segmentChanged(ev: any) {
            console.log("Segment changed", ev);
        },
    },
    setup() {
        return {
            pin,
            wine,
            warning,
            walk,
            rocket,
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            heartOutline,
            heart,
            notifications,
            shareSocial
        };
    },
});
